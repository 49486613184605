import React, { PureComponent } from "react";

import loanOKPesoLogo from "../assets/imgs/loanOKPesoLogo.png";
import logoBg from "../assets/imgs/logoBg.webp";
import redfbBg2 from "../assets/imgs/redfbBg2.webp";
import amount1 from "../assets/imgs/amount1.webp";
import phLogo from "../assets/imgs/phLogo.png";
import testAmountLoading from "../assets/imgs/testAmountLoading.gif";
import testAmountSuccess from "../assets/imgs/testAmountSuccess.png";
import amount2 from "../assets/imgs/amount2.webp";
import amountButton from "../assets/imgs/amountButton.webp";
import testSuccess from "../assets/imgs/testSuccess.png";
import redfbBg3 from "../assets/imgs/redfbBg3.png";
import excellentSuccess from "../assets/imgs/excellentSuccess.webp";
import service from "../assets/imgs/service.png";
import serviceCall from "../assets/imgs/serviceCall.png";
import designMoney from "../assets/imgs/designMoney.webp";
import serviceFb from "../assets/imgs/serviceFb.png";
import axios from "axios";

import UserAgentConponent from "../components/UserAgentConponent";

import "./redOppo.less";
import { message } from "antd";

import sha256 from "crypto-js/sha256";
import {
  reportSys,
  canGoMarket,
  reportDownload,
  reportContactGotoFB,
  reportContactSmartPhone,
  reportContactGlobePhone,
  getUuidByLocalStorage,
} from "../utils/tools";

// ✅️
export class Redfb extends PureComponent {
  state = {
    repayRef: React.createRef(),
    stWayKey: "",
    stChannelKey: "",
    // phoneFocus: false,
    count: 3,
    hasCount: false,
    downLoanUrl: null,
    phone: null,
    smsCode: "",
    hasSend: false,
    canSend: false,
    isErrorMsg: false,
    isShowSmsErrorMsg: false,
    isSending: false,
    testModal: false,
    phoneInfo: {},
    loadingModal: false,
    userAgentVisible: false,
    successModal: false,
    submitModal: false,
    smsCodeModal: false,
    smsTipicModal: false,
    time: 179,
    smsTime: 60,
    openStatus: 0,
    serviceModal: false,
    formattedPhone: {
      phone: null,
      phoneDesc: null,
    },
    callModal: false,
    prevImages: [],
    iframe: false,
    smartType: "smart",
    globeType: "globe",
    source: "redOppo",
    uuid: "",
    userType:"",
  };

  constructor(props) {
    super(props);
    // 定义 ref
    this.phoneInput = React.createRef();
    this.MIN_DISTANCE = 50; // 最小距离
  }

  componentDidMount() {
    this.generateInitialCoins(3);
    this.setState({ uuid: getUuidByLocalStorage() });
    reportSys("openRedPage"); //页面上报

    fetch(window.location.origin + `/api/limit/test/page?a=1&k=14`, {
      method: "GET",
      headers: { "Content-Type": "application/json;charset=UTF-8" },
      mode: "no-cors",
      cache: "default",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.ret === 0) {
          this.setState({
            downLoanUrl: res.data.downloadUrl,
          });
        }
      });
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
    clearInterval(this.interval);
    clearInterval(this.smsTime);
  }
  

  setUserType =() =>{
    const {phone} = this.state;
    fetch(window.location.origin + `/api/limit/test/sms`, {
      method: "POST",
      headers: { "Content-Type": "application/json;charset=UTF-8" },
      // mode:'no-cors',
      cache: "default",
      body: JSON.stringify({
        appId: 1,
        phone,
        sendSms: 0,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.ret === 0) {
          const { reqIp, reqPhone, isRegister } = res.data;
          let fbPhone = sha256(this.checkPhoneForFB(reqPhone)).toString();
          localStorage.setItem("reqIp", reqIp);
          let reqParm = {
            external_id: fbPhone, //外部id
            ph: fbPhone, //手机号
            client_ip_address: reqIp,
            client_user_agent: navigator.userAgent,
          };
          let userType = isRegister === "1" ? "OldUser" : "NewUser";
          localStorage.setItem(
            "isRegister",
            userType
          );
          this.setState({userType: userType})
        }
      });
  }

  updateTime = () => {
    if (this.state.time > 0) {
      this.setState((prevState) => ({ time: prevState.time - 1 }));
    } else {
      clearInterval(this.intervalId);
      // this.setState({ time: 179 });
    }
  };

  updateSmsCodeTime = () => {
    if (this.state.smsTime > 0 && this.state.isSending) {
      if (this.state.isSending === false) {
        this.setState({ isSending: true });
      }
      this.setState((prevState) => ({ smsTime: prevState.smsTime - 1 }));
    } else {
      this.stopSmsTime()
      this.setState({ isSending: false, smsTime: 60 });
    }
  };

  startSmsTime = () => {
    this.smsTime = setInterval(() => {
      this.updateSmsCodeTime();
    }, 1000);
  };

  stopSmsTime = () => {
    clearInterval(this.smsTime);
  };

  gotoResultPage = () => {
    this.setState({
      loadingModal: false,
      openStatus: 2,
    });

    this.interval = setInterval(() => {
      const numCoins = Math.floor(Math.random() * 3) + 1;
      const newCoins = this.generateCoins(numCoins);
      this.setState({
        prevImages: [...this.state.prevImages, ...newCoins],
      });
    }, 1000);

    this.timeout = setTimeout(() => {
      clearInterval(this.interval);
    }, 5000);

    this.intervalId = setInterval(() => {
      // 开启倒计时
      this.updateTime();
    }, 1000);

    setTimeout(() => {
      reportSys("openCollectPage",this.state.userType); //进入红包页面
    }, 500);
  };

  formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  checkPhone(phoneCheck) {
    // 去除空格, 横杠, 括号
    if (!phoneCheck) {
      return "";
    }
    let phone = String(phoneCheck)
      .replace(/ /g, "")
      .replace(/-/g, "")
      .replace(/\(/g, "")
      .replace(/\)/g, "");
    if (phone.length < 3) {
      return phone;
    }
    let areaCode = "+63";
    let preAreaCode = areaCode.replace("+", "");
    // 判断手机号是否以+号开始
    if (phone.indexOf("+") === 0) {
      return phone;
    }
    // 判断手机号是否少了+
    if (phone.indexOf(preAreaCode) === 0) {
      return "+" + phone;
    }
    // 判断手机号是否以00开始
    if (phone.indexOf("00" + preAreaCode) === 0) {
      return "+" + phone.substring(2);
    } // 判断手机号是否以0开始
    if (phone.indexOf("0") === 0) {
      return areaCode + phone.substring(1);
    }
    return areaCode + phone;
  }

  checkPhoneForFB(phoneCheck) {
    let phone = this.checkPhone(phoneCheck);
    return phone.replace("+", "");
  }

  generateInitialCoins = (numCoins) => {
    const initialCoins = this.generateCoins(numCoins);
    this.setState({
      prevImages: initialCoins,
    });
  };
  generateCoins = (numCoins) => {
    const newCoins = [];
    const gridSize = 100; // 定义网格大小

    for (let i = 0; i < numCoins; i++) {
      const size = (Math.random() * (900 - 50) + 300) / 10;
      // 随机网格单元定位
      const cols = Math.floor(window.innerWidth / gridSize);
      const rows = Math.floor(window.innerHeight / gridSize);

      const col = Math.floor(Math.random() * cols);
      const row = Math.floor(Math.random() * rows);

      // 计算基于网格的位置
      const left = col * gridSize + Math.random() * (gridSize - size);
      const top = row * gridSize + Math.random() * (gridSize - size);

      const rotation = Math.random() * 30 * (Math.random() > 0.5 ? 1 : -1);
      const duration = 5;

      newCoins.push({
        id: Date.now() + i,
        size,
        left,
        top,
        duration,
        rotation,
      });
    }

    return newCoins;
  };

  openSmsCodeModal = ()=>{
    reportSys("openEnterCode");
    this.setState({smsCodeModal: true})
  }

  login = async () => {
    const { phone, smsCode,uuid,source } = this.state; 
    try {
      // const response = await axios.post("/api/user/login/phone/third", {
      const response = await axios.post("/oppoh5/api/user/login/phone/third", {
        phone: "+63" + phone,
        code: smsCode,
        uid: uuid,
        source: source,
      });
      if (response.status === 200) {
        // 1206  h5登录 // 1205  app登录过
        if (
          response.data.ret === 0 ||
          response.data.ret === 1206 ||
          response.data.ret === 1205
        ) {
          this.setState({smsCodeModal:false})
          this.gotoResultPage()
        }else{
          this.setState({isShowSmsErrorMsg:true})
        }
      
      } else {
        this.setState({isShowSmsErrorMsg:true})
        message.error(response.data.msg);
      }
    } catch (error) {
      message.error("Login failed. Please check your credentials");
    }
  };

  sendSms = async () => {
    const { phone, isSending,source } = this.state;
    if (isSending) {
      return;
    }
    try {
      // const response = await axios.post("/api/user/login/sms/third", {
      const response = await axios.post("/oppoh5/api/user/login/sms/third", {
        phone: "+63" + phone,
        uid: getUuidByLocalStorage(),
        source: source,
      });
      // console.log(JSON.stringify(response));
      if (response.status === 200) {
        if (response.data.ret === 0) {
          this.setState({
            submitModal: false,
            isSending: true,
          });
          this.openSmsCodeModal()
          this.startSmsTime();
          // 已经注册过按登录流程走
        } else if (response.data.ret === 1206 || response.data.ret === 1205) {
          this.setState({ submitModal: false });
          this.gotoResultPage();
        } else {
          message.error(response.data.msg || "Unexpected error occurred");
          this.setState({ isSending: true });
          this.openSmsCodeModal()
          this.startSmsTime();
        }
      } else {
        // console.log("Error Response:", JSON.stringify(response));
        message.error(response.data.msg || "Unexpected error occurred");
      }

      this.setUserType();
    } catch (error) {
      console.log(error);
      message.error("System Error");
    }
  };

  // handleSendCode = () => {
  //   const { phone, isErrorMsg } = this.state;
  //   if (!phone || phone === "" || isErrorMsg) {
  //     let tMsg = "";
  //     tMsg = "10 digit Philippine number only";
  //     message.error(tMsg, 2);
  //     return;
  //   }
  //   this.setState({ loadingModal: true });
  //   fetch(window.location.origin + `/api/limit/test/sms`, {
  //     method: "POST",
  //     headers: { "Content-Type": "application/json;charset=UTF-8" },
  //     // mode:'no-cors',
  //     cache: "default",
  //     body: JSON.stringify({
  //       appId: 1,
  //       phone,
  //       sendSms: 0,
  //     }),
  //   })
  //     .then((res) => res.json())
  //     .then((res) => {
  //       if (res && res.ret === 0) {
  //         const { reqIp, reqPhone, isRegister } = res.data;
  //         let fbPhone = sha256(this.checkPhoneForFB(reqPhone)).toString();
  //         localStorage.setItem("reqIp", reqIp);
  //         let reqParm = {
  //           external_id: fbPhone, //外部id
  //           ph: fbPhone, //手机号
  //           client_ip_address: reqIp,
  //           client_user_agent: navigator.userAgent,
  //         };

  //         localStorage.setItem(
  //           "isRegister",
  //           isRegister === "1" ? "OldUser" : "NewUser"
  //         );
  //         this.gotoResultPage();
  //       }
  //     });
  // };

  handleValue = (e, type) => {
    const {
      target: { value },
    } = e;
    
    switch (type) {
      case "phone":
        const phonePattern = /^(9[0-9])\d{8}$/;
        this.setState(
          { phone: value, isErrorMsg: !phonePattern.test(value) },
          () => {
            if (phonePattern.test(value) && value.length === 10) {
              this.setState({ submitModal: false });
              // this.handleSendCode();
              this.sendSms();
            }
          }
        );
        break;
      case "smsCode":
        const smsCodePattern = /^\d{5}$/;
        this.setState(
          { smsCode: value, isShowSmsErrorMsg: !smsCodePattern.test(value) },
          () => {
            if (smsCodePattern.test(value) && value.length === 5) {
              this.setState({ submitModal: false });
              // this.handleSendCode();
              this.login();
            }
          }
        );
        break;
      default:
        break;
    }
  };

  handleOpen = () => {
    reportSys("CollectCash-click"); //打开红包上报
    this.setState({ iframe: true }, () => {
      setTimeout(() => {
        this.setState(
          { submitModal: true, openStatus: 1, iframe: false },
          () => {
            reportSys("openEnterPhone"); // 输入手机号弹框
            this.phoneInput.current.focus();
          }
        );
      }, 1500);
    });
  };

  handleDownload = () => {
    reportSys("Gotit-click"); //点击GOT IT
    this.setState({ openStatus: 3 });
    // window.location.href = this.state.downLoanUrl
    setTimeout(() => {
      window.location.href = "oaps://mk/dt?pkg=tech.codeblock.okpeso&goback=1";
    }, 1000);
  };

  reportService = (type) => {
    if (type === this.state.smartType) {
      reportContactSmartPhone();
      return;
    }
    if (type === this.state.globeType) {
      reportContactGlobePhone();
      return;
    }
  };

  handleService = (phone, type) => {
    this.reportService(type);
    let formattedPhone = phone.replace(/(\d{4})(\d{4})(\d{3})/, "$1 - $2 - $3");
    this.setState({
      formattedPhone: {
        phone,
        phoneDesc: formattedPhone,
      },
      callModal: true,
      serviceModal: false,
    });
  };

  handleServiceCancel = () => {
    this.setState({ serviceModal: false });
  };

  handleOpenService = () => {
    reportSys("openContactUs"); //打开客服弹框
    this.setState({ serviceModal: true });
  };

  handleFb = () => {
    reportContactGotoFB();
    window.open("https://www.facebook.com/OKPesoHub", "_blank");
  };

  render() {
    const {
      isErrorMsg,
      testModal,
      count,
      loadingModal,
      userAgentVisible,
      successModal,
      submitModal,
      openStatus,
      serviceModal,
      formattedPhone,
      callModal,
      prevImages,
      iframe,
      smsCodeModal,
      smsTipicModal,
      isShowSmsErrorMsg,
    } = this.state;

    const { downLoanUrl, time } = this.state;
    let UserAgentConponentProps = {
      downLoanApkUrl: downLoanUrl,
    };
    return (
      <div
        className={["redfbTest", openStatus === 3 ? "redfbTest3" : null].join(
          " "
        )}
      >
        {/* <div className={"navs"}>
          <div className='navTitle'>{'OKPeso'}</div>
        </div> */}
        {openStatus !== 0 ? (
          <div className="logo1">
            <img src={loanOKPesoLogo} className="loanOKPesoLogo" alt="" />
            <div className="logoTitle">
              <div className="title1">{"OKPeso-Online Cash Loan"}</div>
              <div className="title2">{"Up to ₱30,000"}</div>
            </div>
          </div>
        ) : null}
        <div
          className={[
            "info",
            [1, 2].includes(openStatus) ? "info1" : null,
            [3].includes(openStatus) ? "info3" : null,
          ].join(" ")}
        >
          {openStatus === 2 &&
            prevImages.map((image) => (
              <img
                key={image.id}
                src={designMoney} // 假设图片路径为 designMoney.png
                className="designMoney"
                alt="Design Money"
                style={{
                  width: image.size + "px",
                  left: image.left + "px",
                  top: image.top + "32.4vw",
                  transform: `rotate(${image.rotation}deg)`, // 应用随机旋转角度
                  animationDuration: `${image.duration}s`,
                }}
              />
            ))}

          {[1, 2].includes(openStatus) ? (
            <img alt="" src={testSuccess} className="testSuccess" />
          ) : null}
          {[0, 1, 2].includes(openStatus) ? (
            <img alt="" src={redfbBg2} className="redfbBg2" />
          ) : null}
          {[3].includes(openStatus) ? (
            <img alt="" src={redfbBg3} className="redfbBg3" />
          ) : null}
          {openStatus === 0 ? (
            <div className="logo">
              <img alt="" src={logoBg} className="logoBg" />
              <img alt="" src={loanOKPesoLogo} className="loanOKPesoLogo" />
              <div className="logoTitle">
                <div className="title1">{"OKPeso-Online Cash Loan App"}</div>
                <div className="title2">{"High limit, safe and fast."}</div>
              </div>
            </div>
          ) : null}
          <div
            className={[
              "amount",
              `amountBg${openStatus}`,
              iframe ? "amountBg4" : null,
            ].join(" ")}
          >
            {openStatus === 0 ? (
              <>
                {
                  <img
                    src={amount1}
                    className={[
                      "amount1",
                      iframe ? "animate-up-fade-out" : null,
                    ].join(" ")}
                    alt=""
                  />
                }
                {!iframe ? (
                  <>
                    <img src={amount2} className="amount2" alt="" />
                    <div className="amountTitle">
                      {"Exclusive for you! You have cash to collect."}
                    </div>
                    <img
                      onClick={this.handleOpen}
                      src={amountButton}
                      className={["amountButton", "pulsate-bck"].join(" ")}
                      alt=""
                    />
                  </>
                ) : null}
              </>
            ) : null}
            {iframe || openStatus === 1 ? (
              <div className="moneyIframe">{"₱5,000"}</div>
            ) : null}
            {openStatus === 2 ? (
              <>
                <div className="install">{"Please download and log in as soon as possible to claim your reward."}</div>
                <div className="money">{"₱5,000"}</div>
                <div className="limit">{"limited seats"}</div>
                <div className={time > 0 ? "time" : "time-hidden"}>
                  <span>End within </span>
                  {this.formatTime(time)}
                </div>
                
                <div
                  onClick={this.handleDownload}
                  className="download pulsate-bck"
                >
                  {"GOT IT"}
                </div>
                {/* <div className="service2" onClick={this.handleOpenService}>
                  <img src={service} className="serviceLogo" alt="" />
                  <div className="serviceTitle">{"Help Center"}</div>
                </div> */}
              </>
            ) : null}

            {openStatus === 3 ? (
              <>
                <div className="excellent">{"You are really excellent!"}</div>
                <div className="excellentBom">
                  <div className="excellentBomTitle">
                    {
                      "Install the app and register to activate, then you can recive a loan."
                    }
                  </div>
                  <img
                    src={excellentSuccess}
                    className="excellentSuccess"
                    alt="excellentSuccess"
                  />
                  <div className="downloading">{"OKPeso is downloading"}</div>
                </div>
              </>
            ) : null}
          </div>

          {openStatus === 3 ? (
            <div className="service" onClick={this.handleOpenService}>
              <img src={service} className="serviceLogo" alt="" />
              <div className="serviceTitle">{"Help Center"}</div>
            </div>
          ) : null}
        </div>

        {testModal && (
          <div className={"testModal"}>
            <div className={"testMask"} />

            <div className={"content"}>
              <div className={"infoModal"}>
                <img
                  className={"loading"}
                  src={testAmountLoading}
                  alt="testAmountLoading"
                />
                <div className={"desc"}>{`${count}s`}</div>
              </div>
            </div>
          </div>
        )}

        {successModal && (
          <div className={"testModal"}>
            <div className={"testMask"} />

            <div className={"content"}>
              <div className={"infoModal"}>
                <img
                  className={"testAmountSuccess"}
                  src={testAmountSuccess}
                  alt="testAmountSuccess"
                />
                <div className={"title"}>{"Congratulations!"}</div>
                <div className={"desc"}>
                  {
                    "You have passed the pre-approval amount test, and your pre-approved loan amount is"
                  }
                </div>
                <div className={"testAmount"}>{"₱ 20,000"}</div>
                <img
                  className={"loading"}
                  src={testAmountLoading}
                  alt="loading"
                />

                <div className={"download"}>{"OKPeso is downloading…"}</div>
                <div className={"end"}>
                  {
                    "Please find the OKPeso APK file that you just downloaded , click Install, and submit the information according to the app prompt."
                  }
                </div>
              </div>
            </div>
          </div>
        )}

        {loadingModal && (
          <div className={"loadingModal"}>
            <div className={"testMask"} />
            <div className={"content"}>
              {
                <div className={"infoModal1"}>
                  <img
                    className={"loading"}
                    src={testAmountLoading}
                    alt="testAmountLoading"
                  />
                </div>
              }
            </div>
          </div>
        )}

        {submitModal && (
          <div className={"submitModal"}>
            <div className={"testMask"} />
            <div className={"content"}>
              {
                <div className={"infoModal"}>
                  <div className="loginTitle">Log in to claim the reward</div>
                  <div className={"phone"}>
                    <img src={phLogo} className={"pre"} alt="" />
                    <span className={"pre"}>+63</span>
                    <input
                      className={"pInput"}
                      minLength={10}
                      maxLength={10}
                      onChange={(e) => this.handleValue(e, "phone")}
                      // onFocus={() => this.setState({ phoneFocus: true })}
                      placeholder={"9**********"}
                      ref={this.phoneInput} // 绑定 ref
                      type="tel"
                      autoFocus
                    />
                  </div>
                  {isErrorMsg && (
                    <div className={"errorMsg"}>
                      {"10 digits starting with 9"}{" "}
                    </div>
                  )}
                </div>
              }
            </div>
          </div>
        )}

        {smsCodeModal && (
          <div className={"submitModal"}>
            <div className={"testMask"} />
            <div className={"content"}>
              {
                <div className={"infoModal"}>
                  <div className="loginTitle">Enter the verification code</div>
                  <div className={"phone"}>
                    <input
                      className={"pInput"}
                      minLength={5}
                      maxLength={5}
                      onChange={(e) => this.handleValue(e, "smsCode")}
                      // onFocus={() => this.setState({ phoneFocus: true })}
                      placeholder={"Enter the 5-digit verifcation code"}
                      type="tel"
                      autoFocus
                    />
                    <span className="smsTime" onClick={this.sendSms}>
                      {this.state.isSending ? this.state.smsTime +"s" : "Send"}
                    </span>
                  </div>
                  <div
                    className="smsTip"
                    onClick={() => {
                      this.setState({ smsTipicModal: true });
                    }}
                  >
                    Can not receive code?
                  </div>
                  {isShowSmsErrorMsg && (
                    <div className={"errorMsg"}>
                      {"Verification code error"}{" "}
                    </div>
                  )}
                </div>
              }
            </div>
          </div>
        )}

        {smsTipicModal && (
          <div className="smsTipicModal">
            <div className="mainMask">
              <div className="mainContent">
                <div className="smsTipicTitle">Can not receive code?</div>
                <div className="smsTipicContent">
                  <p>
                    Having trouble with SMS verification codes? Don't <br />
                    worry, we've got you covered! Here are some quick <br />
                    tips to get those codes rolling in:
                  </p>
                  <p />
                  <span>
                    ✅️Check Your Phone Settings: Just a quick glance
                    <br />
                    to make sure no messages are being blocked.
                  </span>
                  <span>
                    ✅️Switch lt Up: Hop onto a different network andsee if that
                    does the trick.
                  </span>
                  <span>
                    ✅️Reach Out: Give your carrier or service provider
                    <br />a shout - they're there to help!
                  </span>
                  <span>
                    ✅️Double-Check That Number: A simple double-
                    <br />
                    take on the digits you've entered can work wonders.
                  </span>
                  <span>
                    ✅️Spam, Schpam: Sometimes, messages like to
                    <br />
                    hide in the spam folder. Give it a peek!
                  </span>
                  <p>
                    If all else fails, give your local carrier a call - they're
                    <br />
                    ready to lend a hand!
                  </p>
                </div>
                <button
                  className="smsTipicButton"
                  onClick={() => {
                    this.setState({ smsTipicModal: false });
                  }}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        )}

        {serviceModal && (
          <div className={"serviceModal"}>
            <div className={"testMask"} />
            <div className={"content"}>
              {
                <div className={"infoModal"}>
                  <div className="title1">{"CONTACT US"}</div>
                  <div className="title2">{"9:00 AM - 6:00 PM"}</div>
                  <div className="smart" onClick={() => this.handleFb()}>
                    <span className="facebook">
                      <img src={serviceFb} className={"serviceFb"} alt="" />
                      {"Online consultation"}
                    </span>
                    <svg className="next">
                      <use xlinkHref="#iconnext" />
                    </svg>
                  </div>
                  <div
                    className="smart"
                    onClick={() =>
                      this.handleService("09319533595", this.state.smartType)
                    }
                    style={{ borderTop: 0 }}
                  >
                    <span>{"Smart: 09319533595"}</span>
                    <svg className="next">
                      <use xlinkHref="#iconnext" />
                    </svg>
                  </div>
                  <div
                    className="smart"
                    onClick={() =>
                      this.handleService("09052553180", this.state.globeType)
                    }
                    style={{ borderTop: 0 }}
                  >
                    <span>{"Globe: 09052553180"}</span>
                    <svg className="next">
                      <use xlinkHref="#iconnext" />
                    </svg>
                  </div>
                  <div className="Cancel" onClick={this.handleServiceCancel}>
                    {"Cancel"}
                  </div>
                </div>
              }
            </div>
          </div>
        )}

        {callModal && (
          <div className={"callModal"}>
            <div className={"testMask"} />
            <div className={"content"}>
              {
                <div className={"infoModal"}>
                  <img src={serviceCall} className="serviceCall" alt="" />
                  <div className="formattedPhone">
                    {formattedPhone.phoneDesc}
                  </div>
                  <a href={`tel:${formattedPhone.phone}`} className="call">
                    CALL
                  </a>
                  <div
                    className="cancel"
                    onClick={() => {
                      this.setState({ callModal: false });
                    }}
                  >
                    Cancel
                  </div>
                </div>
              }
            </div>
          </div>
        )}

        {userAgentVisible ? (
          <UserAgentConponent {...UserAgentConponentProps} />
        ) : null}
      </div>
    );
  }
}

export default Redfb;
