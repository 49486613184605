import React, { useState, useEffect } from "react";
import "./static/borrow.less";
import { Button, Slider } from "antd";
import { CardSelection } from "./CardSelection";
import a1 from "../assets/imgs/home/borrow/@AUSTRIA.png";
import a2 from "../assets/imgs/home/borrow/@GAMBOA.png";
import a3 from "../assets/imgs/home/borrow/@QUEZON.png";
import a4 from "../assets/imgs/home/borrow/@ANUDON.png";
import a5 from "../assets/imgs/home/borrow/@JOHNPHILIP.png";
import userIcon from "../assets/imgs/home/组 4447@2x.png";
// import trackIcno from "../assets/imgs/home/组 4328@2x.png";
import UserAgentConponent from "../components/UserAgentConponent";
import { canGoMarket, reportDownload } from "../utils/tools";
import { downloadClick,sleep,getDownloadUrl } from "../utils/common";

export const Borrow = ({ isMobile }) => {
  const realTimeArr = [
    { img: a1, content: "AUS*** applied for <span class='real-time-amont-red'>5,400</span> three minutes ago." },
    { img: a2, content: "GAM*** applied for <span class='real-time-amont-red'>12,000</span> four minutes ago." },
    { img: a3, content: "QUE*** applied for <span class='real-time-amont-red'>9,800</span> one minute ago." },
    { img: a4, content: "ANU*** applied for <span class='real-time-amont-red'>10,000</span> five minutes ago." },
    { img: a5, content: "JOH*** applied for <span class='real-time-amont-red'>6,300</span> four minutes ago." },
    // { img: a6, content: "LC*** applied for PHP 2000 one minute ago." },
  ];
  // const [amount, setAmount] = useState(20000);
  const [realTimeItem, setRealTimeItem] = useState(realTimeArr[0]);
  const [realTimeCount, setRealTimeCount] = useState(0);
  const [andro, setAndro] = useState("");
  const [userAgentVisible, setUserAgentVisible] = useState(false);
  const [UserAgentConponentProps, setUserAgentConponentProps] = useState({
    downLoanApkUrl: andro,
  });

  const [sliderValue, setSliderValue] = useState(0);
  const defaultValue = 20000; 
  const animationDuration = 3000; 
  useEffect(() => {
    let startTime = performance.now();
    const animate = (currentTime) => {
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / animationDuration, 1); // 计算动画进度
      // 动态计算滑块的值
      const newValue = Math.round(defaultValue * progress);
      setSliderValue(newValue);

      if (progress < 1) {
        requestAnimationFrame(animate); // 继续下一帧
      }
    };
    requestAnimationFrame(animate);
  }, []);


  const onSliderChange = (value) => {
    // setAmount(value);
    setSliderValue(value)
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      const target = document.querySelector("#realTime-selections");
      realTimeAnimate(target);
      sleep(1000).then(() => {
        if (realTimeCount + 1 >= realTimeArr.length) {
          setRealTimeItem(realTimeArr[0]);
          setRealTimeCount(0);
        } else {
          setRealTimeItem(realTimeArr[realTimeCount + 1]);
          setRealTimeCount(realTimeCount + 1);
        }
      });
    }, 3000);
    return () => {
      clearInterval(intervalId);
    };
  }, [realTimeCount]);

  useEffect(() => {
    const fetchData = async () => {
      console.log("window.location.origin: ", window.location);
      try {
        const url = await getDownloadUrl();
        console.log("Download URL: ", url);
        setAndro(url)
      } catch (error) {
        console.error("Error fetching download URL:", error);
      }
    };
    fetchData(); // 调用异步函数
  }, []);

  const handleDownload = () => {
    
    setTimeout(() => {
      let deviceType = canGoMarket();
      if (deviceType) {
        setUserAgentConponentProps({
          downLoanApkUrl: andro,
        });
        setUserAgentVisible(true);
      } else {
        downloadClick();
      }
    }, 1000);
  };

  const realTimeAnimate = (element) => {
    if (!element) return;
    element.animate(
      [
        { opacity: 1, transform: "translateY(0)" },
        { opacity: 0.75, transform: "translateY(-5px)" },
        { opacity: 0, transform: "translateY(-10px)" },
      ],
      {
        duration: 1000,
        easing: "linear",
      }
    );
  };

  return (
    <div className={isMobile ? "borrow-container-mobile" : "borrow-container"}>
      <div className="main">
        {!isMobile && <CardSelection />}
        <div className="pannel">
          <div className="real-time" id="realTime-selections">
            <img src={realTimeItem.img} alt="realTimeImg" />
            {/* <span>{realTimeItem.content}</span> */}
            <span dangerouslySetInnerHTML={{ __html: realTimeItem.content }} />

          </div>
          <div className="apply-now">
            <div className="customer">
              <img src={userIcon} alt="userIcon" />
              <div className="customer-text-container">
                <p className="title">Hi, Respected Patrons</p>
                <p className="desc">Welcome to use our service</p>
              </div>
            </div>
            <div className="borrow-amount">
              <p className="max-amount">MAX Credit Amount PHP 30,000</p>
              {/* <div> */}
              <div className="main-amount">
                <span className="php">PHP</span>
                <span className="amount">{sliderValue.toLocaleString()}</span>
                {/* <AnimatedNumber start={0} end={6500} duration={2000} className={"amount"}/> */}
              </div>
              <Slider
                value={sliderValue}
                min={500}
                max={30000}
                onChange={onSliderChange}
                className="main-slider"
              />
              <div className="amount-slider">
                <p>PHP500</p>
                <p>PHP30000</p>
              </div>
              <div className="amount-rate-desc">
                <p>Interest as low as 0.06%/day</p>
                <p>Up to 180 days</p>
              </div>
              <Button className="apply-now-button" onClick={handleDownload}>
                APPLY NOW
              </Button>
              {/* <p className="apply-desc">Apply in 3 minutes!</p> */}
            </div>
          </div>
        </div>
      </div>
      {userAgentVisible && <UserAgentConponent {...UserAgentConponentProps} />}
    </div>
  );
};
