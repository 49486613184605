import {reportDownload} from "./tools"
export const homeJumpToSection = (id) => {
    console.log('jumpToSection',id)
    if(id === 'FAQ'){
      window.location.href = '/faq'
        // history.push('/faq');  // 用来导航到 FAQ 页面
    }
    if(id === 'Home'){
      window.location.href = '/'
    }
    if(id === 'PrivacyPolicy'){
      window.location.href = '/privacyAgreement'
    }
    if(id === 'Download'){
      downloadClick();
    }
  };

export const getDownloadUrl = async () => {
  var downLoanUrl = "";
  let downloadK = 1
  if(window.location.href.indexOf('from=search') > -1){
    downloadK = 20
  }
  if (window.location.href.indexOf("sr=1") > -1) {
    downloadK = 30;
  }
  if (window.location.href.indexOf("sr=2") > -1) {
    downloadK = 31;
  }
  if (window.location.href.indexOf("sr=11") > -1) {
    downloadK = 301;
  }
  if (window.location.href.indexOf("sr=21") > -1) {
    downloadK = 311;
  }
  console.log('k params:',downloadK)
  await fetch(
    window.location.origin + `/api/download/page/config?hash=1&k=${downloadK}`,
    {
      method: "GET",
      headers: { "Content-Type": "application/json;charset=UTF-8" },
      mode: "no-cors",
      cache: "default",
    }
  )
    .then((res) => res.json())
    .then((res) => {
      if (res && res.ret === 0) {
        const { data = {} } = res;
        downLoanUrl =data.url
      }
    });
  return downLoanUrl;
};


  export const getDownloadUrlBySession = async() =>{
    var url = sessionStorage.getItem('downloadApkUrl');
    console.log('sessionStorage getItem url:',url)
    if(url === "" || url == undefined){
      url = await getDownloadUrl();
      if(url !== "" && url != undefined){
        console.log('settItem',url)
        sessionStorage.setItem('downloadApkUrl',url)
      }
    }
    return url;
  }

    
  export const downloadClick = async() => {
    const downloadUrl = await getDownloadUrl();
    console.log("downloadClick downloadUrl:",downloadUrl)
    if (downloadUrl) {
      reportDownload();
      console.log("downloading:",downloadUrl)
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = "";
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("Download URL is not available");
    }
  };


  export const genAdjustFbDownloadUrl = (downloadUrl) => {
    if (!downloadUrl) {
      return "";
    }

    const query = new URLSearchParams(window.location.search);
    // 初始化 URL 的基础部分
    let baseUrl = "https://app.adjust.com/";
    let adjustTrackerToken = query.get("p0");
    // 构建带参数的 URL
    let newUrl = new URL(adjustTrackerToken, baseUrl);
    // newUrl.searchParams.set(
    //   "campaign",
    //   `${query.get("p1")} (${query.get("p2")})`
    // );
    // newUrl.searchParams.set(
    //   "adgroup",
    //   `${query.get("p3")} (${query.get("p4")})`
    // );
    // newUrl.searchParams.set(
    //   "creative",
    //   `${query.get("p5")} (${query.get("p6")})`
    // );
    console.log(`${query.get("p1")} (${query.get("p2")})`)
    console.log(`${query.get("p3")} (${query.get("p4")})`)
    console.log(`${query.get("p5")} (${query.get("p6")})`)
    console.log(encodeURIComponent(`${query.get("p1")} (${query.get("p2")})`))
    newUrl.searchParams.set(
      "campaign",
      `${query.get("p1")} (${query.get("p2")})`
    );
    newUrl.searchParams.set(
      "adgroup",
      `${query.get("p3")} (${query.get("p4")})`
    );
    newUrl.searchParams.set(
      "creative",
      `${query.get("p5")} (${query.get("p6")})`
    );

    newUrl.searchParams.set("fbclid", `${query.get("fbclid")}`);
    if (query.get("fbpid")) {
      newUrl.searchParams.set("fbpid", `${query.get("fbpid")}`);
    }
    newUrl.searchParams.set("redirect", downloadUrl);

    // 打印生成的 URL
    let result = newUrl.href.replaceAll("+","%20");
    console.log('genAdjustFbDownloadUrl',result);
    return result;
    /* 
      https://mywebsite.com/123?p0=adjustTrackerToken&p1={{campaign.name}}&p2={{campaign.id}}&p3={{adset.name}}&p4={{adset.id}}&p5={{ad.name}}&p6={{ad.id}}

       https://mywebsite.com/123?p0=adjustTrackerToken&p1=campaignABC&p2=campaignID123&p3=adsetABC&p4=adsetId123&p5=adNameABC&p6=adId123   
    // https://app.adjust.com/adjustTrackerToken?campaign=campaignABC%20%28campaignID123%29&adgroup=adsetABC%20%28adsetId123%29&creative=adNameABC%20%28adId123%29&fbclid=123&fbpid=456 
    // 
    // */
  };
  export const genAdjustRedTTDownloadUrl = (downloadUrl) => {
    if (!downloadUrl) {
      return "";
    }
    // 1kitzwp2
    //https://app.adjust.com/def456?engagement_type=fallback_click&redirect=编码后的跳转地址
    let baseUrl = "https://app.adjust.com/";
    let tracker = "1k1e4h57";
    // 构建带参数的 URL
    let newUrl = new URL(tracker, baseUrl);
    newUrl.searchParams.set("engagement_type", 'fallback_click');
    newUrl.searchParams.set("redirect", downloadUrl);
    console.log('genAdjustRedTTDownloadUrl',newUrl.href)
    return newUrl.href;
  };


 export const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));
