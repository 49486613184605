import * as CryptoJS from "crypto-js";
import { UAParser } from "ua-parser-js";
export function reportSys(eventName,isRegister="") {
  let pageSource = window.location.pathname.replace("/", "");
  if (
    window.location.href.indexOf("from=search") > -1 &&
    window.location.pathname === "/"
  ) {
    pageSource = "from=search";
  }

  if (
    window.location.href.indexOf("sr=1") > -1 &&
    window.location.pathname === "/"
  ) {
    pageSource = "homePage-sr1";
  }

  if (
    window.location.href.indexOf("sr=2") > -1 &&
    window.location.pathname === "/"
  ) {
    pageSource = "homePage-sr2";
  }
  
  let uuid = localStorage.getItem("uuid");
  if (!uuid) {
    uuid = generateUUID();
    localStorage.setItem("uuid", uuid);
  }
  let userType = isRegister ? isRegister :localStorage.getItem("isRegister");
  fetch(window.location.origin + `/webReport`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    mode: "cors",
    cache: "default",
    body: JSON.stringify({
      channel: 0, // 事件来源 0: h5 | 1:App 【必传 int】
      pageLocation: window.location.href, //页面url 【必传 str】
      eventName: eventName, // 事件名称 【必传 str】
      pageSource: pageSource || "home", // 落地页 【必传 str】
      // utmSource: "google", // 广告来源 【str】
      // utmCode: window.fbqId, // 广告系列代码 【str】
      uuid,
      brandMark: !!getDeviceType() ? getDeviceType() : "Other",
      h5Ua: navigator.userAgent,
      userType: userType ? userType : null,
    }),
  });
}

export function isSchemeSupported(scheme) {
  try {
    // 尝试创建一个 URL 对象
    new URL(`${scheme}://details?packagename=tech.codeblock.okpeso`);
    return true;
  } catch (e) {
    return false;
  }
}

export function generateUUID() {
  const bytes = CryptoJS.lib.WordArray.random(16);
  const hex = bytes.toString(CryptoJS.enc.Hex);

  return [
    hex.slice(0, 8),
    hex.slice(8, 12),
    hex.slice(12, 16),
    hex.slice(16, 20),
    hex.slice(20, 32),
  ].join("-");
}

export function getUuidByLocalStorage() {
  let uuid = localStorage.getItem("uuid");
  if (!uuid) {
    uuid = generateUUID();
    localStorage.setItem("uuid", uuid);
  }
  return uuid
}
export function reportPage() {
  reportSys("openPage");
}
export function reportDownload() {
  if(window.location.href.indexOf('from=search') > -1){
    const { gtag } = window;
    gtag('event', 'conversion', {'send_to': 'AW-11327427724/j0z7CNmJvOoZEIypq5kq'});
  }
  reportSys("downloadClick");
}
export function reportGoAppStore() {
  reportSys("gotoAppStore");
}
export function reportDownloadApk() {
  reportSys("downloadApk");
}
export function reportContactGotoFB() {
  reportSys("contactGotoFB");
}
export function reportContactSmartPhone() {
  reportSys("contactSmartPhone");
}
export function reportContactGlobePhone() {
  reportSys("contactGlobePhone");
}
// export function reportGotoFB() {
//   reportSys("gotoFB");
// }

// export function reportSmartPhone() {
//   reportSys("smartPhone");
// }
// export function reportGlobePhone() {
//   reportSys("globePhone");
// }

export function getDeviceType() {
  //获取设备类型
  // let userAgent = navigator.userAgent.toLowerCase();
  // let deviceType = null;

  // if (/Huawei/i.test(userAgent) || /HONOR/i.test(userAgent)) {
  //   deviceType = "Huawei";
  // } else if (/OPPO/i.test(userAgent)) {
  //   deviceType = "OPPO";
  // } else if (/VIVO/i.test(userAgent)) {
  //   deviceType = "VIVO";
  //   // } else if (/Xiaomi/i.test(userAgent)) {
  //   //   deviceType = 'xiaomi';
  //   // } else if (/Samsung/i.test(userAgent)) {
  //   //   deviceType = 'Samsung';
  //   // }
  // } else {
  //   deviceType = null;
  // }
  let userAgent = navigator.userAgent.toLowerCase();
  const parser = new UAParser(userAgent).getDevice();
  const { vendor } = parser;
  let vendorMore = ModelK(vendor);
  return vendorMore;
}

export function canGoMarket() {
  let userAgent = navigator.userAgent.toLowerCase();
  const parser = new UAParser(userAgent).getDevice();
  const { vendor } = parser;
  let vendorMore = ModelK(vendor);
  let ua = ["Huawei", "OPPO", "Vivo"];
  if (ua.includes(vendorMore)) {
    return true;
  }

  return false;
}

export function ModelK(vendor) {
  let vendorTemp = vendor;

  if (!vendor) {
    let userAgent = navigator.userAgent.toUpperCase();
    //SamsungBrowser
    if (/SAMSUNGBROWSER/i.test(userAgent)) {
      vendorTemp = "Samsung";
    }
    //model k
    else if (/; K\)/i.test(userAgent)) {
      vendorTemp = "K";
    }
  }
  return vendorTemp;
}
export function checkMobile() {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    return true;
  }
  return false;
}

export function checkPhone(phoneCheck) {
  // 去除空格, 横杠, 括号
  if (!phoneCheck) {
    return "";
  }
  let phone = String(phoneCheck)
    .replace(/ /g, "")
    .replace(/-/g, "")
    .replace(/\(/g, "")
    .replace(/\)/g, "");
  if (phone.length < 3) {
    return phone;
  }
  let areaCode = "+63";
  let preAreaCode = areaCode.replace("+", "");
  // 判断手机号是否以+号开始
  if (phone.indexOf("+") === 0) {
    return phone;
  }
  // 判断手机号是否少了+
  if (phone.indexOf(preAreaCode) === 0) {
    return "+" + phone;
  }
  // 判断手机号是否以00开始
  if (phone.indexOf("00" + preAreaCode) === 0) {
    return "+" + phone.substring(2);
  }
  // 判断手机号是否以0开始
  if (phone.indexOf("0") === 0) {
    return areaCode + phone.substring(1);
  }
  return areaCode + phone;
}

export function checkPhoneForFB(phoneCheck) {
  let phone = this.checkPhone(phoneCheck);
  return phone.replace("+", "");
}
